import { Dayjs } from 'dayjs'
import { LiteralUnion } from 'type-fest'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { SignalWallTypes } from '~/modules/signal-wall/SignalWallTypes'

export class SignalWallAPI extends Urlu {
  /**
   * @example
   *   // ## 當配合 useSwr
   *
   *   const res = useSWR(
   *     // 只是一個 key 可以不用放 url
   *     'signalWall',
   *
   *     //有給第二個參數(fetcher) 就好
   *     async () => {
   *       const $data = await apirc.signalWall.get({
   *         signal_id: SignalWallId.盤中突破日內高點,
   *         from: dayAPI().subtract(day, 'day'),
   *       })
   *
   *       return $data
   *     },
   *     {
   *       refreshInterval: 5000,
   *       initialData: [
   *         { symbol: 'GC-1' },
   *         { symbol: 'NQ-1' },
   *         { symbol: 'JY-1' },
   *         { symbol: 'YM-1' },
   *         { symbol: 'ES-1' },
   *       ] as any,
   *     },
   *   )
   */
  async get(params: {
    signal_id: LiteralUnion<SignalWallId, string>
    symbol?: string

    /** 預設：一小時前 */
    from?: Dayjs

    /** 預設：當下 */
    to?: Dayjs

    /** 預設：1 */
    page?: number

    /** 預設：20 */
    size?: number
  }) {
    const { data } = await this.request.axios.get<SignalWallTypes.Datum[]>(this.baseUrl, {
      params: {
        ...params,
        from: params.from?.unix(),
        to: params.to?.unix(),
        from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
        to__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
      },
    })

    return data
  }
}
