import { useInterval, useMount, useUpdateEffect } from 'react-use'
import { apirc } from '~/configs/apirc'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { useMeStore } from '~/modules/SDK/me/useMeStore'

/** 持續根據 email 和 agent 辨認權限，向後端請求並更新前端 store.state */
export const useMeVerifyJwtContinue = () => {
  const agentName = useMeStore(state => state.agentName)
  const uid = useMeStore(state => state.meUserState?.uid)
  const email = useMeStore(state => state.meFirebaseState?.email)

  // initial load
  useMount(() => {
    if (email) {
      apirc.me.api.getMe().then(user => {
        const target = user?.subscriptions.find(item => {
          return item.agentName === fr_agents.agent && item.productName === 'default'
        })

        if (!target) apirc.me.api.signup()
      })
    }
  })

  // on jwt,agentName changed
  useUpdateEffect(() => {
    apirc.me.api.getMe()
    apirc.me.api.getInfo()
  }, [email, agentName])

  // WHY NEED THIS:
  // new user login to firebase, but not sync to our backend yet
  useInterval(() => {
    if (agentName !== 'null' && email && !uid) apirc.me.api.getMe()
  }, 1000)
}
