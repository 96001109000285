import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import dayAPI from '~/utils/dayAPI'

export class StockScreenerAPI extends Urlu {
  async fetchTradeDays(options?: { symbol?: string }) {
    const days = await this.request.axios.get<{ date: string }[]>(`/latest_trade_date`, {
      params: {
        symbol: options?.symbol || '',
        size: 30,
      },
    })

    const intraday = await this.request.axios.get<{ date: string }>('/latest_trade_date', {
      params: {
        symbol: options?.symbol || '',
        intraday: true,
      },
    })

    return {
      intraday: dayAPI(intraday.data?.date).set('hours', 0).set('minutes', 0).set('seconds', 0),
      days:
        days.data?.map(datum =>
          dayAPI(datum.date).set('hours', 0).set('minutes', 0).set('seconds', 0),
        ) || [],
    }
  }
}
