import axios from 'axios-0-27-2'
import { Dayjs } from 'dayjs'
import { proxy } from 'valtio'
import { apirc } from '~/configs/apirc'
import { ContractInfo } from '~/configs/StaticJsonAPIs'
import { StaticTypes } from '~/pages/heineken_template/_private/StaticTypes'
import dayAPI from '~/utils/dayAPI'

/**
 * - 收集所有來自 backend 的 static 資料
 * - 注意，資料傳遞可能是異步的，如果太早取值，可能會 null
 */
export const staticStore = proxy({
  contract: {
    value: {} as Awaited<ReturnType<typeof apirc.staticJson.fetchContractInfo>>,

    /**
     * - 拿取每點價值
     * - 拿取小數點位數 `pricescale`
     * - 拿取最小跳動 `minmov`
     * - 其它請參考 {@link ContractInfo}
     */
    getInfo(symbol?: string) {
      if (!symbol) return null

      return staticStore.contract.value[symbol]
    },
  },

  symbol: {
    tw50: [] as StaticTypes.CommonSymbolList['TW50'],
    otc50: [] as StaticTypes.CommonSymbolList['OTC50'],
    osFuturesList: {
      agriculture: [],
      energy: [],
      metal: [],
      overall: [],
    } as StaticTypes.CommonSymbolList['osFuturesList'],
    stockFuturesMapper: {} as StaticTypes.CommonSymbolList['stockFutures'],
    stockCategory: {} as StaticTypes.CommonSymbolList['stockCategory'],
    yahooSubList: {} as StaticTypes.CommonSymbolList['yahooSubList'],
  },

  /**
   * ### 有交易資料的日期
   *
   * #### 異步向後端問取後更新
   */
  tradedDate: {
    /**
     * #### 異步向後端問取後更新，初始值為前端的 `now()`
     *
     * @example
     *   // ## 當後端更新出 intraday 的正確值時，獲取
     *   const intraday = useSnapshot(staticStore.tradedDate).intraday
     */
    intraday: dayAPI(),

    /**
     * ### 最近的一天有交易資料的日期
     *
     * #### 異步向後端問取後更新，初始值為前端的 `now()`
     *
     * #### 並非 intraday
     *
     * @example
     *   //
     *   // 如果你要拿第二天
     *   //
     *   tradedDate.days?.[1]
     */
    get day0() {
      return this.days?.[0] || dayAPI().set('hours', 0).set('minutes', 0).set('seconds', 0)
    },

    /**
     * 所有具備交易資料的日期們
     *
     * - `.days?.[0]` 是最近的一天, 等同於 `.day0`
     * - `.days?.[1]` 是最近的第二天, 以此類推
     * - 最多 30 天 (size=30)
     */
    days: [] as Dayjs[],
  },

  /**
   * #### 介面開發者：你不需要使用到這支
   *
   * #### 底層開發者：因為是純靜態資料，初始化它時，用來作一次撈 value 就就差不多夠了
   */
  async fetchAndUpdateState() {
    //
    // 交易日期
    //
    const data = await apirc.stock.screenerAPI.fetchTradeDays()
    staticStore.tradedDate.intraday = data.intraday
    staticStore.tradedDate.days = data.days

    //
    // 每點價值、之類的
    //
    const contractInfo = await apirc.staticJson.fetchContractInfo()

    staticStore.contract.value = contractInfo

    //
    // 映射 SymbolCode 和 SymbolDisplayName
    //

    const commonSymbolList = await axios.get<StaticTypes.CommonSymbolList>(
      `https://storage.googleapis.com/symbol-config/common_symbol_list.json`,
    )

    staticStore.symbol.tw50 = commonSymbolList.data.TW50
    staticStore.symbol.otc50 = commonSymbolList.data.OTC50
    staticStore.symbol.osFuturesList = commonSymbolList.data.osFuturesList
    staticStore.symbol.stockCategory = commonSymbolList.data.stockCategory
    staticStore.symbol.stockFuturesMapper = commonSymbolList.data.stockFutures
    staticStore.symbol.yahooSubList = commonSymbolList.data.yahooSubList
  },
})
