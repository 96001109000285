/** 簡易列表，在某些 page target 中關閉特定模組，在 _app.page.tsx 使用 */

/** firebase, member 的 auth module
 * 參照：AuthRootProvider.tsx
 */
export const AUTH_MODULE_DISABLED_LIST = ['trading_summary']

/** 一些金融商品以及交易相關基本 API，
 * 詳細參照：BackendDataComponent.tsx
 */
export const STATIC_DATA_DISABLED_LIST = ['trading_summary']
