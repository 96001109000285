import { Dayjs } from 'dayjs'
import { AnyObject } from 'tsdef'
import { TvAPIsTypes } from '~/configs/apirc/TvAPIsTypes'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'

export class TvAPIs extends Urlu {
  wsUrl = ''

  get request() {
    return super.request.withAgent.withProduct
  }

  async fetchKbars(params: { symbol: string; resolution: string; from: Dayjs; to: Dayjs }) {
    const kbars = await this.request.axios
      .get<TvAPIsTypes.Bar[]>(`/history`, {
        params: {
          ws: true,
          ...params,
          from: params.from.unix(),
          to: params.to.unix(),
          //
          from_DEBUG_: params.from.format('YYYY-MM-DD HH:mm:ss'),
          to_DEBUG_: params.to.format('YYYY-MM-DD HH:mm:ss'),
        },
      })
      .then(res => res.data)

    return kbars
  }

  async fetchAll() {
    const staticJsonOfAll = await this.request.withAgent.axios
      .get<
        {
          symbol: string | 'TX-1'
          full_name: string | 'TX-1'
          type: string | 'futures'
          description: string | '台指期'
          exchange: string | 'TFE'
        }[]
      >(`/search?query=&exchange=&type=all_types`)
      .then(res => res.data)

    return staticJsonOfAll
  }

  /**
   * 返回 e.g. `1678575186
   *
   * @example
   *   //
   *   // 返回之後的用法
   *   const serverTime = dayAPI(1678575186 * 1000).format()
   *
   *   serverTime.unix() // 1678575186
   */
  fetchServerTime = async () => {
    return await this.request.axios.get<number>(`/time`).then(res => res.data)
  }

  fetchConfig = async () => {
    return await this.request.axios
      .get<{
        /** E.g. `['1', '3', '5', '15', '30', '60', '1D', 'W']` */
        supported_resolutions: string[]
        supports_group_request: boolean
        supports_marks: boolean
        supports_search: boolean
        supports_time: boolean
        supports_timescale_marks: boolean
        /**
         * E.g.
         *
         * - 0: {name: "全部商品", value: "all_types"}
         * - 1: {name: "國內期貨", value: "futures"}
         * - 2: {name: "海外期貨", value: "os_futures"}
         * - 3: {name: "指數", value: "index"}
         * - 4: {name: "選擇權", value: "option"}
         * - 5: {name: "股票", value: "stock"}
         */
        symbols_types: { name: string; value: string }[]
        /** E.g. `'Asia/Taipei'` */
        timezone: string
      }>(`/config`)
      .then(res => res.data)
  }

  search = async (
    params: Partial<{
      limit: 30 | number
      /**
       * 傳入要被搜尋的字串
       *
       * E.g. `NQ`
       */
      query: string
      exchange: string
      type: 'os_futures' | string
    }>,
  ) => {
    return await this.request.axios
      .get<
        {
          description: string
          exchange: string
          full_name: string
          symbol: string
          type: string
        }[]
      >(`/search`, { params })
      .then(res => res.data)
  }

  resolveSymbol = async (params: { symbol: string }) => {
    return await this.request.axios.get(`/symbols`, { params }).then(res => res.data)
  }

  fetchHistory = async (params: AnyObject) => {
    return await this.request.axios
      .get<{
        bars: TvAPIsTypes.Bar[]
        metaData: {
          nextTime: number
          noData: boolean
        }
      }>(`/history`, { params })
      .then(res => res.data)
  }
}
