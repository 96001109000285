import { css } from '@emotion/react'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  TextFieldProps,
  dialogClasses,
  outlinedInputClasses,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useRouter } from 'next/router'
import React, { memo, useEffect, useState } from 'react'
import useMedia from '~/hooks/useMedia'
import { useText } from '~/hooks/useText'
import { globalBlueGrey, globalRed, WHITE } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState'
import dayAPI from '~/utils/dayAPI'
import { Dayjs } from 'dayjs'
import { apirc } from '~/configs/apirc'

const commonFieldProps: TextFieldProps = { size: 'small', variant: 'outlined', fullWidth: true }

export const EXPERIENCE: { key: string; value: number }[] = [
  { key: '一年內', value: 0 },
  { key: '1年 - 3年', value: 1 },
  { key: '3年 - 5年', value: 3 },
  { key: '5年 - 10年', value: 5 },
  { key: '10年以上', value: 10 },
]

/** 試用前的使用者資訊填寫 */
const FillDetailFormDialog = memo(function FillDetailFormDialog() {
  const router = useRouter()
  const { state, acts } = useFuturesaiTutorialState.useContainer()
  const name = useText()
  const gender = useText('M')
  const [birth, setBirth] = useState(dayAPI().format('YYYY/MM/DD'))
  const phone = useText()
  const [consent, setConsent] = useState(true)
  const [experienceYear, setExperienceYear] = useState('0')
  const [error, setError] = useState(false)
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false)
  const { isPhone, isPc } = useMedia()

  const datePickerProps = {
    variant: 'inline',
    label: '生日',
    value: birth,
    format: 'YYYY/MM/DD',
    size: 'small',
    inputVariant: 'outlined',
    fullWidth: true,
    renderInput: (params: TextFieldProps) => <TextField {...params} />,
    onChange: (date: Dayjs | null, keyboardInputValue?: string | undefined) => {
      if (date) setBirth(date.format('YYYY-MM-DD'))
    },
  }

  return (
    <Dialog
      css={css`
        .${dialogClasses.paper} {
          background: transparent;
          box-shadow: inherit;
        }
      `}
      open={state.fillFormOpen ?? false}
      onClose={() => {
        acts.setFillFormOpen(false)
      }}
    >
      <div
        css={css`
          position: relative;
          ${flex.v.allCenter};
        `}
      >
        <div
          css={css`
            background: url('/futuresai/fill-detail-bacakground.png');
            background-size: contain;
            ${flex.v.allCenter};
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-bottom: 16px;
          `}
        >
          <div
            css={css`
              ${flex.v.allCenter};
              margin: 128px ${isPhone ? 16 : 64}px 0px;
              padding: 32px;
              background: ${WHITE}bb;
              border-radius: 8px;
              & > * {
                margin: 8px 0 !important;
              }
            `}
          >
            {error && (
              <h4
                css={css`
                  color: ${globalRed.r700};
                  margin: 0;
                `}
              >
                請填寫完整
              </h4>
            )}
            <TextField
              {...commonFieldProps}
              type='text'
              label='名字'
              value={name.value}
              onChange={name.acts.onChange}
            />

            <div
              css={css`
                & .${outlinedInputClasses.input} {
                  padding: 8.5px 14px;
                }
              `}
            >
              <DatePicker
                {...datePickerProps}
                inputFormat='yyyy/MM/DD'
              />
            </div>

            <div
              css={css`
                ${flex.h.crossCenter};
                width: 100%;
                h5 {
                  margin: 0;
                }
              `}
            >
              <Radio
                checked={gender.value === 'M'}
                onChange={gender.acts.onChange}
                value='M'
              />
              <h5>男</h5>
              <Radio
                checked={gender.value === 'F'}
                onChange={gender.acts.onChange}
                value='F'
              />
              <h5>女</h5>
            </div>
            <TextField
              {...commonFieldProps}
              label='手機號碼'
              value={phone.value}
              onChange={phone.acts.onChange}
            />
            <FormControl fullWidth>
              <InputLabel>接觸期貨年限</InputLabel>
              <Select
                label='接觸期貨年限'
                size='small'
                value={experienceYear}
                onChange={e => setExperienceYear(e.target.value)}
              >
                {EXPERIENCE.map((kv, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={kv.value}
                    >
                      <div>{kv.key}</div>
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <div css={flex.h.crossCenter}>
              <Checkbox
                defaultChecked={consent === true}
                onChange={e => setConsent(e.target.checked)}
                color='primary'
              />
              <p>我願意收到最新資訊</p>
            </div>
          </div>
          <div
            css={css`
              ${isPhone ? flex.v.default : flex.h.allCenter};
              color: ${WHITE};
              font-size: 0.7rem;
              margin: 16px;
            `}
          >
            點擊註冊的同時，表示您同意期天資訊的
            <Button
              onClick={() => {
                setPrivacyDialogOpen(true)
              }}
              css={css`
                color: ${globalBlueGrey.bg300};
              `}
              size='small'
            >
              使用及隱私條款
            </Button>
          </div>
        </div>
        <Button
          variant='contained'
          color='warning'
          css={css`
            bottom: 16px;
            margin: auto;
          `}
          onClick={() => {
            if (!name.value?.trim() || !birth || !phone.value?.trim()) {
              setError(true)
              return
            }
            apirc.me.api
              .postInfo({
                name: name.value ?? '',
                birthday: birth ?? '',
                gender: gender.value === 'M' ? '男' : '女',
                mobilePhone: phone.value ?? '',
                tradingSeniority: parseInt(experienceYear ?? '1'),
                newsletterConsent: consent,
              })
              .then(() => {
                router.reload()
              })
            setError(false)
          }}
        >
          {state.tutorialProduct?.status === 'premium' ? '更新會員資料' : '註冊'}
        </Button>
      </div>
      <Dialog
        onClose={() => setPrivacyDialogOpen(false)}
        open={privacyDialogOpen}
      >
        <RuleContent />
      </Dialog>
    </Dialog>
  )
})

const RuleContent = memo(function RuleContent() {
  return (
    <Paper
      css={css`
        padding: 16px;
      `}
    >
      <h1>隱私權聲明</h1>
      <p>
        歡迎您使用「期天看盤系統」（以下簡稱本網站），為了讓您能夠安心使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
      </p>
      <h3>一、隱私權保護政策的適用範圍</h3>
      <p>
        隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
      </p>
      <h3>二、個人資料的蒐集、處理及利用方式</h3>
      <ul>
        <li>
          當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
        </li>
        <li>
          本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
        </li>
        <li>
          於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
        </li>
        <li>
          為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
        </li>
      </ul>
      <h3>三、資料之保護</h3>
      <ul>
        <li>
          本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
        </li>
        <li>
          如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
        </li>
      </ul>
      <h3>四、網站對外的相關連結</h3>
      <p>
        本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
      </p>
      <h3>五、與第三人共用個人資料之政策</h3>
      <p>
        本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
      </p>
      <p>前項但書之情形包括不限於：</p>
      <ul>
        <li>經由您書面同意。</li>
        <li>法律明文規定。 </li>
        <li>為免除您生命、身體、自由或財產上之危險。</li>
        <li>
          與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
        </li>
        <li>
          當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
        </li>
        <li>有利於您的權益。</li>
        <li>
          本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
        </li>
      </ul>
      <h3>六、Cookie之使用</h3>
      <p>
        為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行
        。
      </p>
      <h3>七、隱私權保護政策之修正</h3>
      <p>本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。</p>
    </Paper>
  )
})
export default FillDetailFormDialog
