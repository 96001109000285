import { css } from '@emotion/react'
import {
  TextFieldProps,
  TextField,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { memo, useEffect, useState } from 'react'
import useMedia from '~/hooks/useMedia'
import { WHITE } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { EXPERIENCE } from './FillDetailFormDIalog'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import dayAPI from '~/utils/dayAPI'
import { Dayjs } from 'dayjs'
import { apirc } from '~/configs/apirc'

const commonFieldProps: TextFieldProps = { size: 'small', variant: 'outlined', fullWidth: true }

/**
 * 期天主站點開會員資訊Dialog，點下「新增/編輯會員資料」的表格
 *
 * `FillDetailFromDialog.tsx`與之相似，是專門給註冊後新會員要「試用」前填寫用的
 */
const EditUserInfoForm = memo<ReactProps<{ parentAction?: () => void }>>(function EditUserInfoForm(
  props,
) {
  const meInfoState = useMeStore(state => state.meInfoState)
  const { isPhone } = useMedia()
  const [copyInfo, setCopyInfo] = useState<MeTypes.MeInfoState>()
  useEffect(() => {
    if (meInfoState && !copyInfo) {
      setCopyInfo({ ...meInfoState })
    }
  }, [meInfoState, copyInfo])
  return (
    <div css={flex.v.allCenter}>
      <div
        css={css`
          ${flex.v.allCenter};
          background: ${WHITE}bb;
          margin: 16px ${isPhone ? 8 : 16}px 0px;
          padding: 16px 16px 0;
          border-radius: 8px;
          & > * {
            margin: 8px 0 !important;
          }
        `}
      >
        <TextField
          {...commonFieldProps}
          type='text'
          label='名字'
          value={copyInfo?.name}
          onChange={e => {
            setCopyInfo({ ...copyInfo, name: e.target.value })
          }}
        />
        <DatePicker
          label='生日'
          value={
            copyInfo?.birthday
              ? dayAPI(copyInfo?.birthday).format('YYYY-MM-DD')
              : dayAPI().format('YYYY-MM-DD')
          }
          inputFormat='YYYY/MM/DD'
          renderInput={params => <TextField {...params} />}
          onChange={(value: Dayjs | null) => {
            if (value) setCopyInfo({ ...copyInfo, birthday: value.format('YYYY-MM-DD') })
          }}
        />
        <div
          css={css`
            ${flex.h.default};
            width: 100%;
          `}
        >
          <Radio
            checked={copyInfo?.gender === '男'}
            onChange={e => {
              if (e.target.checked) setCopyInfo({ ...copyInfo, gender: '男' })
            }}
            value='男'
          />
          <h5>男</h5>
          <Radio
            checked={copyInfo?.gender === '女'}
            onChange={e => {
              if (e.target.checked) setCopyInfo({ ...copyInfo, gender: '女' })
            }}
            value='女'
          />
          <h5>女</h5>
        </div>
        <TextField
          {...commonFieldProps}
          label='手機號碼'
          value={copyInfo?.mobilePhone}
          onChange={e => {
            setCopyInfo({ ...copyInfo, mobilePhone: e.target.value })
          }}
        />
        <FormControl fullWidth>
          <InputLabel>接觸期貨年限</InputLabel>
          <Select
            value={copyInfo?.tradingSeniority ?? 3}
            onChange={e => {
              setCopyInfo({ ...copyInfo, tradingSeniority: e.target.value as number })
            }}
          >
            {EXPERIENCE.map((kv, index) => {
              return (
                <MenuItem
                  key={index}
                  value={kv.value}
                >
                  <div>{kv.key}</div>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <div css={flex.h.crossCenter}>
          <Checkbox
            defaultChecked={meInfoState?.newsletterConsent}
            onChange={e => {
              setCopyInfo({ ...copyInfo, newsletterConsent: e.target.checked })
            }}
            color='primary'
          />
          <p>我願意收到最新資訊</p>
        </div>
        <Button
          fullWidth
          color='warning'
          variant='contained'
          onClick={() => {
            apirc.me.api.postInfo({
              ...copyInfo,
            })

            props.parentAction && props.parentAction()
          }}
        >
          更新
        </Button>
      </div>
    </div>
  )
})

export default EditUserInfoForm
